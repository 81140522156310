import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Button, Flex } from 'antd';
import { usePharmacyEntryHooks } from 'src/hooks/pages/use.pharmacy.entry.hooks';
import { EntryHeader } from 'src/components/Organisms/Common/entry.header';
import {
  PharmacyEntryFooter,
  PharmacyEntryList,
  PharmacyGroupAdd,
} from 'src/components/Organisms/Pharmacy';
import { SecondaryButton } from 'src/components/Atoms/SecondaryButton';

export const PharmacyEntry: FC = memo(() => {
  const {
    hospital,
    onChange,
    disabled,
    universities,
    schedule,
    onUniversitySelect,
    entry,
    isModalOpen,
    setIsModalOpen,
    onAddGroup,
    areas,
    user,
    update,
    universityId,
    sumEntry,
  } = usePharmacyEntryHooks();

  return (
    <Default
      title={'薬局実習エントリー'}
      isSelect={2}
      contents={
        'エントリーが確定した病院名が表示されています。病院名をクリックすると同じグループの薬局一覧が表示されますので、\n' +
        '実習期間別に実数希望人数を入力してください。'
      }
    >
      {user && (
        <EntryHeader
          authId={user?.authId}
          onSelect={onUniversitySelect}
          universities={universities}
          between={schedule?.['pharmacyEntry']}
        />
      )}
      <Flex
        vertical={true}
        align={'flex-start'}
        style={{ alignSelf: 'stretch' }}
      >
        {universityId &&
          hospital.map((group) => (
            <PharmacyEntryList
              key={group.id}
              group={group}
              entry={entry}
              sumEntry={sumEntry}
              onChange={onChange}
              disabled={disabled}
            />
          ))}
      </Flex>
      {universityId && (
        <SecondaryButton size={'large'} onClick={() => setIsModalOpen(true)}>
          薬局グループ追加
        </SecondaryButton>
      )}
      <PharmacyGroupAdd
        areas={areas}
        handleOk={onAddGroup}
        handleCancel={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
      />
      {universityId && (
        <Button type={'primary'} size={'large'} onClick={update}>
          登録
        </Button>
      )}
      <PharmacyEntryFooter entry={entry} />
    </Default>
  );
});
